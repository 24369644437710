/**
 * External dependencies.
 */
import React, { useContext } from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import GlobalContext from "contexts/global";
import Link from "components/link/link";
import Notice from "components/notice/notice";
import Nav from "components/nav/nav";
import Logo from "../../assets/images/svg/logo.svg";
import MobileMenu from "components/mobile-menu/mobile-menu";

interface HeaderProps {
  notice?: boolean;
  dark?: boolean;
}

const Header: React.FC<HeaderProps> = ({ notice, dark }) => {
  const { global: data }: any = useContext(GlobalContext);
  return (
    <div
      className={classNames("header", {
        "header--notice": notice && data.Notice,
      })}
    >
      <div
        className={classNames("header__inner", { "header__inner--dark": dark })}
      >
        {notice && data.Notice && <Notice />}
        <div className="container">
          <div className="header__flex">
            <div className="logo">
              <Link href="/" title="logo">
                <Logo viewBox="0 0 130 32" />
              </Link>
            </div>
            <Nav target="HeaderLeftMenu" />
            <Nav target="HeaderRightMenu" className="nav--utils" />
            <MobileMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
