import classNames from "classnames";
import { HTMLInputTypeAttribute } from "react";

interface FieldProp {
  placeholder: string;
  onChange: any; // () => void Fast fix. TODO
  value: HTMLInputTypeAttribute;
  type: HTMLInputTypeAttribute;
  className?: string;
  id?: string;
}

const Field: React.FC<FieldProp> = ({
  placeholder,
  onChange,
  value,
  type,
  className,
  id,
}) => {
  return (
    <div className="form-control">
      <input
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={classNames("field", className)}
        id={id}
      />
    </div>
  );
};

export default Field;
