/**
 * External dependencies.
 */
import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import MenuToggle from "../../assets/images/svg/icon-menu.svg";
import Nav from "components/nav/nav";

const MobileMenu = () => {
  const [active, setActive] = useState(false);
  const router = useRouter();
  const handleClick = () => {
    setActive(!active);
    document.querySelector("body")?.classList.toggle("overflow-hidden");
  };

  useEffect(() => {
    setActive(false);
    document.querySelector("body")?.classList.remove("overflow-hidden");
  }, [router.asPath]);

  return (
    <div className="mobile-menu">
      <button
        onClick={handleClick}
        className={classNames("mobile-menu__toggle", { "is-active": active })}
      >
        <MenuToggle />
      </button>
      {active && (
        <div className="mobile-menu__modal">
          <Nav target="HeaderLeftMenu" />
          <Nav target="HeaderRightMenu" className="nav--utils" />
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
