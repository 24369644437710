/**
 * External dependencies
 */
import { useRouter } from 'next/router';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';

/**
 * Internal dependencies
 */
import { useLang } from 'contexts/lang-context';

const LangSwitch = () => {
  const router = useRouter();
  const { urls, labels } = useLang();
  const selectOptions = labels.map((item: any) => ({
    name: item.name,
    value: item.locale,
  }))
  
  const handleChange = async (langCode: any) => {
    if (router.locale == langCode) {
      return;
    }

    const target = urls.find((item: any) => item.locale.toLowerCase() === langCode);
    const q = router.query.q || '';
    
    if(!target) {
      return;
    }

    const url: any = {
      pathname: target.url,
    };

    if(q) {
      url.query = { q }
    }
    router.push(url, '', { locale: langCode });
  }

  return (
    <div className="lang-switch">
      <span>Language:</span>
      <span>
        <SelectSearch
          options={selectOptions}
          value={router.locale}
          className="select-search custom-select"
          onChange={handleChange}
        />
      </span>
    </div>
  );
}

export default LangSwitch;
