/**
 * External dependencies.
 */
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { t } from "@lingui/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Internal dependencies.
 */
import * as fbq from "../../lib/fpixel";
import Field from "components/field/field";
import IconArrowRight from "../../assets/images/svg/icon-arrow-right.svg";

interface FromNewsletterProp {
  email: string;
}

const schema = yup
  .object()
  .shape({
    email: yup.string().email().required(),
  })
  .required();

const FromNewsletter = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FromNewsletterProp>({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FromNewsletterProp> = (data) => {
    fetch("https://manage.kmail-lists.com/ajax/subscriptions/subscribe", {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        "cache-control": "no-cache",
      },
      body: `g=${process.env.NEXT_PUBLIC_KLAVIYO_LIST_ID}&email=${data.email}&$source=Custom Form`,
    })
      .then((res) => res.json())
      .then((res) => {
        fbq.event("Lead");
        toast(t`A confirmation email has been sent.`);
      })
      .catch((err) => {
        toast(t`An error occurred. Please try again later.`);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="form-newsletter">
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Field
            onChange={field.onChange}
            placeholder={t`Email address`}
            type="email"
            value={field.value}
            className={classNames("field--fill", { error: errors.email })}
          />
        )}
      />

      <button
        type="submit"
        aria-label="button"
        className="form-newsletter__btn"
      >
        <IconArrowRight />
      </button>
      <ToastContainer position="bottom-center" autoClose={2000} />
    </form>
  );
};

export default FromNewsletter;
