/**
 * External dependencies.
 */
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import Link from "components/link/link";
import GlobalContext from "contexts/global";
import { useContext } from "react";

interface NavProp {
  target: string;
  className?: string;
}

interface NavItemProp {
  id: number;
  Text?: string;
  Link?: string;
  Button: boolean;
}

const Nav = ({ target, className }: NavProp) => {
  const { global: data }: any = useContext(GlobalContext);

  return (
    <div className={classNames("nav", className)}>
      <ul>
        {data &&
          data[target]?.map(
            (item: NavItemProp) =>
              item.Link &&
              item.Text && (
                <li key={item.id}>
                  <Link
                    href={item.Link}
                    className={classNames({ "btn btn--fill": item.Button })}
                  >
                    {item.Text}
                  </Link>
                </li>
              )
          )}
      </ul>
    </div>
  );
};

export default Nav;
