/**
 * External dependencies.
 */
import { useContext, useMemo } from "react";
import { Trans } from "@lingui/macro";
/**
 * Internal dependencies.
 */
import GlobalContext from "contexts/global";
import Logo from "../../assets/images/svg/logo.svg";
import SocialFacebook from "../../assets/images/svg/social-facebook.svg";
import SocialInstagram from "../../assets/images/svg/social-instagram.svg";
import SocialPinterest from "../../assets/images/svg/social-pinterest.svg";
import SocialTwitter from "../../assets/images/svg/social-twitter.svg";
import SocialYoutube from "../../assets/images/svg/social-youtube.svg";
import SocialLinkedIn from "../../assets/images/svg/social-linked.svg";
import Link from "components/link/link";
import LangSwitch from "components/lang-switch/lang-switch";
import FromNewsletter from "blocks/from-newsletter/from-newsletter";
import classNames from "classnames";

interface FooterProp {
  alt?: boolean;
}

const Footer: React.FC<FooterProp> = ({ alt }) => {
  const { global: data }: any = useContext(GlobalContext);
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const { facebook, twitter, instagram, youtube, pinterest, linkedin } = data?.SocialLinks ?? {};
  const FooterLinks = data?.FooterLinks;
  const BottomLinks = data?.BottomLinks;

  return (
    <div className={classNames("footer", { "footer--alt": alt })}>
      <div className="container">
        <div className="footer__inner">
          <div className="footer__col">
            <div className="footer__logo">
              <Link href="/" title="logo">
                <Logo viewBox="0 0 130 32" />
              </Link>
            </div>
            <h6>
              <Trans>Stay connected</Trans>
            </h6>
            <FromNewsletter />
            <ul className="social-list">
              {facebook && (
                <li>
                  <a href={facebook} target="_blank" title="facebook">
                    <SocialFacebook />
                  </a>
                </li>
              )}
              {twitter && (
                <li>
                  <a href={twitter} target="_blank" title="Twitter">
                    <SocialTwitter />
                  </a>
                </li>
              )}
              {instagram && (
                <li>
                  <a href={instagram} target="_blank" title="Instagram">
                    <SocialInstagram />
                  </a>
                </li>
              )}
              {youtube && (
                <li>
                  <a href={youtube} target="_blank" title="Youtube">
                    <SocialYoutube />
                  </a>
                </li>
              )}
              {pinterest && (
                <li>
                  <a href={pinterest} target="_blank" title="Pinterest">
                    <SocialPinterest />
                  </a>
                </li>
              )}
              {linkedin && (
                <li>
                  <a href={linkedin} target="_blank" title="LinkedIn">
                    <SocialLinkedIn />
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="footer__col">
            <div className="menu-row">
              {FooterLinks?.map((item: any) => (
                <div className="footer__menu" key={item.id}>
                  <p>{item.Title}</p>
                  <ul>
                    {item.FooterLink.map((link: any) => (
                      <li key={`link-${link.id}`}>
                        <Link href={link.URL}>{link.Text}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="footer__row">
          <LangSwitch />
        </div>
        <div className="copy-row">
          <p>
            &copy; {currentYear} <Trans>DigiFist All rights reserved.</Trans>
          </p>
          <ul>
            {BottomLinks &&
              BottomLinks.map((item: any) => (
                <li key={item.id}>
                  <Link href={item.URL}>{item.Text}</Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
