/**
 * External dependencies.
 */
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

interface LinkElementProp {
  href: string;
  children: any;
  className?: string;
  title?: string;
  RelNoFollow?: boolean;
  OpenInNewTab?: boolean;
  style?: any;
}

const LinkElement: React.FC<LinkElementProp> = ({
  children,
  href,
  className,
  title,
  RelNoFollow,
  OpenInNewTab,
  style,
}) => {
  const { locale, asPath } = useRouter();
  if (!href) {
    return <></>;
  }
  const hrefHandle = href.match(/^\//) ? `/${locale}${href}` : href;

  return (
    <Link
      href={hrefHandle}
      locale={false}
      target={OpenInNewTab ? "_blank" : "_self"}
      rel={RelNoFollow ? "nofollow" : ""}
      className={classNames(className, {
        "is-active": asPath === href,
      })}
      title={title}
      style={style}
    >
      {children}
    </Link>
  );
};

export default LinkElement;
