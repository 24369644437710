import React, { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useLang } from "contexts/lang-context";

type Props = {
  data: any;
};

const Hreflang: React.FC<Props> = ({ data }) => {
  const router = useRouter();
  const { setUrls } = useLang();
  const localizations =
    data?.attributes?.localizations?.data || data?.localizations?.data;
  const path = (loc?: any) => {
    const slug = loc?.attributes?.slug_overwrite || loc?.attributes?.slug;
    if (router.asPath === "/") {
      return "";
    } else if (slug) {
      return router.pathname.replace(/\/[^\/]+$/, `/${slug}`);
    }

    return router.asPath;
  };

  const localeUrls = localizations
    ? localizations.map((item: any) => ({
        id: item.id,
        locale: item.attributes?.locale,
        url: path(item),
      }))
    : [];

  const urls = [
    {
      locale: data?.attributes?.locale || data?.locale,
      url: path(),
    },
    ...localeUrls,
  ];

  useEffect(() => {
    setUrls(urls);
  }, [router.asPath]);

  return (
    <Head>
      <link
        rel="alternate"
        hrefLang={data?.attributes?.locale || data?.locale}
        href={`${process.env.NEXT_PUBLIC_SITE_URL}/${
          data?.attributes?.locale?.toLowerCase() || data?.locale?.toLowerCase()
        }${path()}`}
      />
      {localizations &&
        localeUrls.map((item: any) => (
          <link
            key={item.id}
            rel="alternate"
            hrefLang={item.locale}
            href={`${
              process.env.NEXT_PUBLIC_SITE_URL
            }/${item.locale.toLowerCase()}${item.url}`}
          />
        ))}
    </Head>
  );
};

export default Hreflang;
