/**
 * External dependencies.
 */
import Head from "next/head";
import { useContext } from "react";
import classNames from "classnames";

/**
 * Internal dependencies.
 */
import Header from "components/header/header";
import Footer from "components/footer/footer";
import GlobalContext from "contexts/global";
import { getStrapiMedia } from "../utils";
import Hreflang from "blocks/hreflang/hreflang";

interface LayoutProp {
  children: any;
  light?: boolean;
  notice?: boolean;
  title: string;
  desc: string;
  dark?: boolean;
  className?: string;
  FooterAlt?: boolean;
  hreflang?: boolean;
  growth?: boolean;
}

const Layout: React.FC<LayoutProp> = ({
  children,
  light,
  notice,
  title,
  desc,
  dark,
  className,
  FooterAlt,
  hreflang = true,
  growth = false,
}) => {
  const { global, data, current }: any = useContext(GlobalContext);

  return (
    <div
      className={classNames(
        "wrapper",
        growth ? "wrapper--growth" : "",
        className
      )}
    >
      {hreflang && <Hreflang data={current || data} />}
      <Head>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <link
          rel="icon"
          href={
            global && global.Favico?.data
              ? getStrapiMedia(global.Favico)
              : "/favicon.ico"
          }
        />
      </Head>
      <Header notice={notice} dark={growth ? light : !light} />
      <div className={classNames("main", { "main--dark": dark })}>
        {children}
      </div>
      <Footer alt={FooterAlt} />
    </div>
  );
};

export default Layout;
