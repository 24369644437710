/**
 * External dependencies.
 */
import { useContext } from "react";
import ReactMarkdown from "react-markdown";

/**
 * Internal dependencies.
 */
import GlobalContext from "contexts/global";
import Link from "components/link/link";

const Notice = () => {
  const { global: data }: any = useContext(GlobalContext);

  return (
    <div
      className="notice"
      style={{
        backgroundColor: data.notice_bg_color,
        color: data.notice_text_color,
      }}
    >
      <ReactMarkdown
        components={{
          a: ({ node, children }: any) => (
            <Link
              href={node.properties.href}
              style={{ color: data.notice_link_color }}
            >
              {children}
            </Link>
          ),
        }}
      >
        {data.Notice}
      </ReactMarkdown>
    </div>
  );
};

export default Notice;
